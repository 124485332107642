@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-ssp: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei";
  --container-pd: 10px;

  --section-width: 1200px;
  --app-height: 100vh;

  --font-primary-color: #0080CC;
  --font-secondary-color: #333333;
  --font-tertiary-color: #666666;

  --color-ref-border: #CCCCCC;

  --tw-content: '';
}

@media (min-width: 768px) {
  :root {
    --container-pd: 16px;
  }
}

@media (min-width: 1024px) {
  :root {
    --container-pd: 32px;
  }
}

@layer base {
  html,
  body {
    padding: 0;
    margin: 0;
  }

  article,
  footer,
  header,
  main,
  nav,
  section {
    display: block;
  }

  html {
    font-size: 100px;
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-overflow-scrolling: touch;
  }

  body {
    color: var(--font-secondary-color);
    font-size: .16rem;
    line-height: .24rem;
    font-weight: 400;
    font-family: var(--font-ssp);
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  img {
    border-width: 0;
  }

  input[type=button],
  button {
    padding: 0;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
  }


  select,
  input,
  textarea {
    outline: none;
    margin: 0;
    padding: 0;
    border-width: 0;
    outline: none;
    background-color: transparent;
  }

  input,
  button {
    font-weight: 400;
    font-family: var(--font-ssp);
    font-size: inherit;
    -webkit-appearance: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 200;
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  * {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--color-ref-border);
  }
}

@layer components {
  .section-wrap {
    @apply transition-all duration-300 ease-in-out max-w-full mx-auto px-[var(--container-pd)] w-[calc(var(--section-width)+var(--container-pd)*2)];
  }

  .menu-btn {
    @apply relative flex justify-center items-center flex-col
           before:absolute before:w-full before:h-2 before:bg-primary-dark before:transition-transform before:top-0
           after:absolute after:w-full after:h-2 after:bg-primary-dark after:transition-transform after:bottom-0
           child-[i]:h-2 child-[i]:bg-primary-dark child-[i]:w-full;
  }
  .menu-btn.open {
    @apply child-[i]:hidden before:top-auto before:w-4/5 before:rotate-45 after:bottom-auto after:w-4/5 after:-rotate-45;
  }
}
